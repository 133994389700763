//
//
//
//
//

import GlobalMixin from '../shared/GlobalMixins.js'
import GamePlayMixin from '../shared/GamePlayMixin.js'
export default {
  name: 'Logout',
  mixins: [GlobalMixin, GamePlayMixin],
  async created () {
    this.setLoggingOut(true);
    await this.destroyToken();
    await this.sleep(300);
    await this.$router.push({name: 'login'});
  },
  // beforeRouteEnter (to, from, next) {
  //   next(vm => {
  //     if (!vm.loggedIn) {
  //       next({ name: 'login' })
  //     }
  //     // access to component instance via `vm`
  //   })
  // }
}
